export type AnyFunc = (...args: any[]) => any;

/**
 * @summary Debounces specified function by 'delay' milliseconds.
 * @param f Function to debounce
 * @param delay Delay in milliseconds
 */
export function debounce<T extends AnyFunc>(f: T, delay: number): (...args: Parameters<T>) => Promise<ReturnType<T>> {
    return debounceEx(f, delay).promise;
}

/**
 * @summary Debounces specified function by 'delay' milliseconds. Also
 * exposes cancel method.
 * @param f Function to debounce
 * @param delay Delay in milliseconds
 */
export function debounceEx<T extends AnyFunc>(f: T, delay: number): {
    promise: (...args: Parameters<T>) => Promise<ReturnType<T>>,
    cancel: () => void
} {
    let timer: any = null;
    const cancel = () => {
        clearTimeout(timer);
        timer = null;
    }
    const promise = (...args: any[]): Promise<any> => {
        cancel();
        return new Promise((resolve) => {
            timer = setTimeout(
                () => resolve(f(...args)),
                delay
            );
        });
    }
    return { promise, cancel };
}

export const format = (text: string, ...args: any[]): string => {
    if (!text || !args.length) {
        return text;
    }

    var source = Object.assign({}, args);

    return text.replace(/{([_A-Za-z0-9\-.]+?)(:.+?)?}/g, (match: string, path: string, format: string) => {
        let index = parseInt(path, 10);
        let replacement = isNaN(index) ? get(source, path) : args[index];
        if (format && format.length > 1) {
            // Format is for example ":remove-empty". Skip colon, and process the key
            switch (format.substr(1).toLowerCase()) {
                case "remove-empty":
                    if (!replacement)
                        replacement = "";
                    break;
            }
        }
        return typeof replacement !== "undefined" ? String(replacement) : match;
    });
}

export const get = (obj: any, path: string): any => {
    const get = getter(path);
    try {
        return get(obj);
    }
    catch {
        return undefined;
    }
    /*
    const arr: string[] = typeof path === "string" ? path.split(".") : path,
        len: number = arr.length;
    let fn: Function | Object = parent || this || window,
        i: number = 0;
    while (fn && i < len) fn = fn[arr[i++]];
    return fn;
    */
}

const __getterCache = { undefined: function() { return this; } };
export const getter = function (expression: string): ((obj: any) => any) {
    const key = expression;
    if (!__getterCache[key]) {
        expression = expression || '';
        if (expression && expression.charAt(0) !== '[') {
            expression = '.' + expression;
        }
        /* eslint-disable no-new-func */
        __getterCache[key] = new Function('d', 'return d' + expression);
        /* eslint-enable no-new-func */
    }
    return __getterCache[key];
}

export function guid() {
    let id: string = '', i: number, random: number;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            id += '-';
        }
        id += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
    }
    return id;
}

let _uid = 0;
/**
 * Returns unique integer id.
 */
export function uid(): number {
    return ++_uid;
}

/**
 * @summary Returns distinct values of an array.
 */
export function distinct<T>(array: T[]): T[] {
    return array.filter((value: T, index: number, self: T[]) => self.indexOf(value) === index);
}

export type ClassNamesArgs = string | string[] | { [className: string]: boolean };
/**
 * @summary Utility for conditionally joining classNames together.
 * It should work like 'classnames' npm package. For more info see: https://www.npmjs.com/package/classnames
 * @param args string | string[] | { [className: string]: boolean }
 * @returns Class name joined into one string
 */
export function classNames(...args: ClassNamesArgs[]) {
    if (!args) return null;
    const classes = [];
    args?.forEach(arg => {
        if (typeof(arg) === "string") {
            classes.push(arg);
        } else if (typeof(arg) === "object") {
            if (Array.isArray(arg)) {
                classes.push(...arg);
            } else {
                classes.push(...Object.keys(arg || {}).filter(k => !!arg[k]))
            }
        }
    });
    return classes.join(" ");
}